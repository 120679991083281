"use client";
import AppleMap from "@wt/shared/components/maps/mapkit";

export const MapSelectorTutorial = ({
  latitude,
  longitude,
  label,
}: {
  latitude: number;
  longitude: number;
  label?: string;
}) => {
  const coord: any = {
    longitude: longitude,
    latitude: latitude,
  };
  return <AppleMap selectedLocation={coord} markerLabel={label} />;
};
