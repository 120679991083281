import { Gaegu, Inter } from "next/font/google";

const interStyle = Inter({
    weight: ["300", "500", "800"],
    subsets: ["latin"],
});

const gaeguStyle = Gaegu({
  weight: ["300", "400", "700"],
  subsets: ["latin"],
});

export default {
    inter: interStyle,
    gaegu: gaeguStyle,
}