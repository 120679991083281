'use client';
import { Coordinate } from '@wt/shared/components/maps/mapkit/types';
import { Spinner } from '@wt/shared/components/spinner/spinner';
import {
  FeatureVisibility,
  Map,
  MapInteractionEvent,
  Marker,
} from '@wt/shared/libs/mapkit';
import { ReactNode, useEffect, useRef, useState } from 'react';

export interface AppleMapProps {
  className?: string;
  onLocationSelect?: (e: MapInteractionEvent) => void;
  selectedLocation?: Coordinate | undefined;
  markerLabel?: string;
}

export interface AppleMapResultsProps {
  children?: ReactNode;
}

const AppleMap = (props: AppleMapProps) => {
  const [marker, setMarker] = useState<Coordinate | null | undefined>(
    props.selectedLocation
  );
  const mapRef = useRef<mapkit.Map | null>(null);
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const handleSelect = (e: MapInteractionEvent) => {
    setMarker(props.selectedLocation);
    props.onLocationSelect && props.onLocationSelect(e);
  };

  useEffect(() => {
    if (props.selectedLocation) {
      setMarker(props.selectedLocation);
    }
  }, [props.selectedLocation]);

  const centerCoordinate = (point: Coordinate, animate = true) => {
    const map = mapRef.current;
    if (map && point) {
      map.setCenterAnimated(
        new mapkit.Coordinate(point.latitude, point.longitude),
        animate
      );
    }
  };

  const mapLoaded = () => {
    if (marker) {
      centerCoordinate(marker, false);
    }
    const spinner = loaderRef.current;
    const mapContainer = mapContainerRef.current;
    if (mapContainer && spinner) {
      spinner.style.opacity = '0';
      mapContainer.style.opacity = '1';
    }
  };

  useEffect(() => {
    if (marker) {
      centerCoordinate(marker);
    }
  }, [marker]);

  return (
    <div className={`relative h-full w-full ${props.className ?? ''}`}>
      <div
        ref={loaderRef}
        className="pointer-events-none absolute inset-0 z-[999] flex flex-col items-center justify-center bg-black/20 opacity-100 backdrop-blur-md transition-all duration-300 ease-in-out"
      >
        <Spinner />
      </div>
      <div
        ref={mapContainerRef}
        className="absolute inset-0 h-full w-full opacity-0 transition-opacity duration-300 ease-linear"
      >
        <Map
          ref={mapRef}
          allowWheelToZoom={true}
          onLoad={mapLoaded}
          showsMapTypeControl={false}
          showsCompass={FeatureVisibility.Hidden}
          showsUserLocationControl={false}
          onSingleTap={handleSelect}
        >
          {marker && (
            <Marker
              title={props.markerLabel ?? 'Your Guess'}
              latitude={marker.latitude}
              longitude={marker.longitude}
            />
          )}
        </Map>
      </div>
    </div>
  );
};

export default AppleMap;
