'use client';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { WhereTakenLogo } from '../assets/logo/WhereTakenLogo';

function WheretakenClassicModal() {
  const [open, setOpen] = useState(false);

  // If there is a "modal seen" cookie, don't show the modal.
  // Otherwise show it, but set the cookie
  useEffect(() => {
    const key = 'seen-wheretaken-classic';
    const alreadySeen = localStorage.getItem(key);
    if (alreadySeen) return;
    setOpen(true);
    localStorage.setItem(key, '' + Date.now());
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        '&.MuiBox-root': {
          border: 'none',
          outline: 'none',
        },
      }}
    >
      <Box sx={{ border: 'none', outline: 'none' }}>
        <ModalContent handleClose={handleClose} />
      </Box>
    </Modal>
  );
}

export default WheretakenClassicModal;

const ModalContent = (props: { handleClose: () => void }) => {
  return (
    <div className="m-4 flex min-w-[300px] flex-col space-y-6 rounded-3xl bg-base-100 p-6 text-lg md:min-w-[350px]">
      <div className="flex w-full flex-row items-center">
        <div className="grow text-center text-3xl">
          Looking for{' '}
          <span className="font-bold">
            WHERE
            <span style={{ color: '#6490f1' }}>TAKEN</span>
          </span>
          &nbsp;🌎 ?
        </div>
        <Link href={'#'} onClick={props.handleClose}>
          <CloseRoundedIcon className="m-0 fill-current text-2xl leading-none" />
        </Link>
      </div>

      <div className="mb-2 w-full">
        <p>
          It's now called{' '}
          <span className="font-bold">
            WHERE
            <span style={{ color: '#6490f1' }}>TAKEN</span>
          </span>{' '}
          <span className="italic">Classic</span>, and{' '}
          <a className="underline" href="https://wheretaken.teuteuf.fr">
            here's
          </a>{' '}
          where you can find it!
        </p>
        <p className="text-center">
          <a className="underline" href="https://wheretaken.teuteuf.fr">
            https://wheretaken.teuteuf.fr
          </a>
        </p>
      </div>

      <div className="mb-2 w-full text-center">
        <Link
          href={'/game'}
          prefetch={true}
          className="btn btn-primary shrink text-lg font-bold uppercase text-white md:h-[61px] lg:text-2xl"
          onClick={props.handleClose}
        >
          <span className="mr-1">Play</span>
          <div className="relative top--1">
            <WhereTakenLogo />
          </div>
        </Link>
      </div>
    </div>
  );
};
