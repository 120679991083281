import './info-block.css';

import { memo, ReactNode } from 'react';

interface InfoBlockProps {
  title: string;
  children: ReactNode;
  wideColumn?: boolean;
}

export const InfoBlock = memo((props: InfoBlockProps) => {
  return (
    <section
      className={`info-block justify-between ${props?.wideColumn ? 'md:col-span-2' : ''}`}
    >
      <div className="info-header">
        <div className="info-step" />
        <div className="info-description">{props.title}</div>
      </div>
      <div className="info-content">{props.children}</div>
    </section>
  );
});
